import { SET_INPUT_VALUE, SET_ROTATE_VALUE, SET_INTERACTION_VALUE } from './constants';

const resetInputValues = () => ({
  filter: 'All',
  sort: 'Region',
});

export const resetRotateValues = () => ({
  angle: 0,
  startAngle: 0,
  currentAngle: 0,
  indexAngle: 0,
  rotate: 0,
});

const resetInteractionValues = () => ({
  dragging: false,
  inertia: false,
});

export const setInitialState = ({ filter = '', sort = '' }) => ({
  ...initialState,
  inputs: {
    ...initialState.inputs,
    filter: filter,
    sort: sort,
  },
  rotateAngles: {
    ...initialState.rotateAngles,
  },
  interaction: {
    ...initialState.interaction,
  },
});

export const initialState = {
  inputs: resetInputValues(),
  rotateAngles: resetRotateValues(),
  interaction: resetInteractionValues(),
};

export default function reducer(state, action) {
  switch (action.type) {
    case SET_INPUT_VALUE:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputName]: action.value,
        },
      };
    case SET_ROTATE_VALUE:
      return {
        ...state,
        rotateAngles: {
          ...state.rotateAngles,
          ...action.value,
        },
      };
    case SET_INTERACTION_VALUE:
      return {
        ...state,
        interaction: {
          ...state.interaction,
          [action.key]: action.value,
        },
      };
  }
}
