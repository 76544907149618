// Require all Twig component behaviours
const exportObj = {};

const req = require.context('./', true, /behaviour\.js$/);
req.keys().forEach((key) => {
  const behaviour = req(key).default;
  if (!behaviour.id) {
    throw new Error(`Behaviour ${behaviour.name} is missing id`);
  }
  exportObj[behaviour.id] = behaviour;
});

const reqGeneric = require.context('./behaviours/', true, /\.js$/);
reqGeneric.keys().forEach((key) => {
  const behaviour = reqGeneric(key).default;
  if (!behaviour.id) {
    throw new Error(`Behaviour ${behaviour.name} is missing id`);
  }
  exportObj[behaviour.id] = behaviour;
});

export default exportObj;
