export default class TranslateDropdownBehaviour {
  static id = 'translate-dropdown';

  constructor(node) {
    this.node = node;
    window.addEventListener('translateInitialised', this.init);
    node.addEventListener('click', this.handleClick);
  }

  init = () => {
    const iso = this.getCurrentIso();
    this.update(iso);
    this.enable();
  }

  getCurrentIso () {
    const matches = /googtrans=\/en\/([\w|-]+)/.exec(document.cookie);
    return matches ? matches[1] : 'en';
  }

  enable () {
    const buttonNodes = this.node.querySelectorAll('button[data-iso]');
    [...buttonNodes].forEach((node) => {
      node.disabled = false
    });
  }

  update (iso) {
    const activeButtonNode = this.node.querySelector('button[aria-selected="true"]');
    if (activeButtonNode) {
      activeButtonNode.removeAttribute('aria-selected');
      activeButtonNode.parentNode.removeAttribute('data-selected');
    }

    const buttonNode = this.node.querySelector(`button[data-iso="${iso}"]`);
    if (buttonNode) {
      buttonNode.setAttribute('aria-selected', 'true');
      buttonNode.parentNode.setAttribute('data-selected', 'true');
    } else {
      console.log('Language button not found', iso);
    }
  }

  handleClick = (event) => {
    const buttonNode = event.target.closest('button');

    if (!buttonNode || !buttonNode.dataset.iso) {
      return;
    }

    event.preventDefault();

    const selectNode = document.querySelector('#google_translate_element2 select');
    selectNode.value = buttonNode.dataset.iso;
    selectNode.dispatchEvent(new Event('change', { bubbles: true }));

    this.update(buttonNode.dataset.iso);
  }
}
