import React, { memo } from 'react';
import clsx from 'clsx';
import { SizeMe } from 'react-sizeme';

import { getCoords } from 'components/WheelChart/helpers';
import { CHART_TYPE_ROTATE } from '../constants';

import ScoreLabel from '../ScoreLabel';

import './styles.scss';

function WheelLabel({
  country,
  countryCode,
  href,
  badge,
  badgeLabel,
  cx,
  cy,
  width,
  height,
  policy,
  revised,
  type,
  index,
  radius,
  angleSegment,
  withLabels = false,
}) {
  const rotated = type === CHART_TYPE_ROTATE;

  const angleStart = angleSegment * index;

  const { x, y } = getCoords(angleStart, radius, cx, cy);

  const top = angleStart < 90 || angleStart >= 270;
  const left = angleStart >= 180;

  let rotate = `rotate(${angleStart - (left ? 270 : 90)}deg) translate(${
    left ? '-100%' : '0'
  }, -50%)`;
  let labelX = x;
  let labelY = y;

  if (rotated) {
    labelY = y;
  }

  if (!rotated) {
    if (top) {
      labelY = y - height;
    }

    if (left) {
      labelX = x - width;
    }
  }

  return (
    <>
      <div
        data-country={countryCode}
        className={clsx(
          'wheel__label',
          left && 'wheel__label--left',
          !rotated && top && 'wheel__label--top',
          revised && 'wheel__label--revised'
        )}
        style={{
          top: labelY,
          left: labelX,
          transform: rotated ? rotate : null,
        }}
      >
        <a href={href} className="wheel__anchor">
          <span>{country}</span>
        </a>
        {!rotated && withLabels && <ScoreLabel text={badgeLabel} variant={badge} policy={policy} />}
      </div>
    </>
  );
}

function SizedWheelLabel(props) {
  return (
    <SizeMe monitorHeight>
      {({ size }) => <WheelLabel width={size.width} height={size.height} {...props} />}
    </SizeMe>
  );
}

export default memo(SizedWheelLabel);
