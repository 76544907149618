import React from 'react';
import clsx from 'clsx';

import './styles.scss';

import Icon from '../Icon';

function FormMessage({ children, className, variant }) {
  return (
    <div className={clsx('form-msg', className)}>
      <Icon
        className={clsx('form-msg__icon', variant === 'error' && 'form-msg__icon--error')}
        id={variant === 'error' ? 'warning' : 'tick'}
      />
      <p className="form-msg__copy">{children}</p>
    </div>
  );
}

export default FormMessage;
