// This hack fixes Preact duplicating content when rendering on a page within Google Translate
// https://github.com/preactjs/preact/issues/948#issuecomment-714753263

// Options object is not exported by `preact/compat`
import { options } from 'preact';

const FONT_AS_TEXT = {
  localName: {
    value: null,
  },
  nodeType: {
    value: 3,
  },
  data: {
    get() {
      return this._data;
    },
    set(v) {
      v += '';
      if (v === this._data) return;
      if (!this.parentNode) {
        console.warn('No parent node');
        return;
      }
      const t = document.createTextNode((this._data = v));
      this.parentNode.replaceChild(t, this);
      this.__v.__e = t;
    },
  },
};
const ib = Element.prototype.insertBefore;
Element.prototype.insertBefore = function(child, ref) {
  if (ref && ref.nodeType === 3 && child && child.localName === 'font') {
    for (let i in child) {
      if (i.startsWith('_gt_')) {
        ref._font = child;
        child._data = ref.data;
        Object.defineProperties(child, FONT_AS_TEXT);
        break;
      }
    }
  }
  return ib.apply(this, arguments);
};
const oldDiffedHook = options.diffed;
options.diffed = (vnode) => {
  if (vnode.type === null && vnode.__e) {
    const font = vnode.__e._font;
    if (font) {
      vnode.__e = font;
      font.__v = vnode;
      font.data = vnode.props;
    }
  }
  if (oldDiffedHook) {
    oldDiffedHook(vnode);
  }
};
