import React from 'react';
import clsx from 'clsx';

import './styles.scss';

import {
  getOriginAngle,
  getScoreDotStartAngle,
  getScoreDotOriginRadius,
  getCoords,
} from 'components/WheelChart/helpers';

import { CHART_TYPE_ARC } from '../constants';

function WheelOverlay(props) {
  const { angleStart, className } = props;

  return (
    <g className={clsx('wheel__overlay', className)}>
      {angleStart.map((angle) => {
        return (
          <g key={angle}>
            <OutlineMask {...props} angleStart={angle} className="wheel__overlay-outline" />
          </g>
        );
      })}
    </g>
  );
}

function OutlineMask({
  type,
  sections,
  angle,
  angleStart,
  arcLength,
  cx,
  cy,
  maxScore,
  dotRadii,
  radius,
}) {
  const originAngle = getOriginAngle(type, angleStart, angle);

  const { outerDotRad, innerDotRad, scoreDotRad, dotOverlapDiameter } = dotRadii;

  return (
    <g>
      <defs>
        <mask id={`inner-shape-mask--${angleStart}`}>
          {sections.map((angle, index) => {
            const originRadius = getScoreDotOriginRadius(type, radius, dotOverlapDiameter, index);
            const startAngle = getScoreDotStartAngle(type, angle, index, originAngle, originRadius);
            const { x, y } = getCoords(startAngle, originRadius, cx, cy);
            return (
              <circle
                key={startAngle}
                fill="white"
                r={outerDotRad + 1}
                transform={`translate(${x}, ${y})`}
              />
            );
          })}
          {sections.map((angle, index) => {
            const originRadius = getScoreDotOriginRadius(type, radius, dotOverlapDiameter, index);
            const startAngle = getScoreDotStartAngle(type, angle, index, originAngle, originRadius);
            const { x, y } = getCoords(startAngle, originRadius, cx, cy);
            return (
              <circle
                key={startAngle}
                fill="black"
                r={innerDotRad}
                transform={`translate(${x}, ${y})`}
              />
            );
          })}
        </mask>
      </defs>
      <g className="wheel__overlay--outline" mask={`url(#inner-shape-mask--${angleStart})`}>
        {sections.map((angle, index) => {
          const originRadius = getScoreDotOriginRadius(type, radius, dotOverlapDiameter, index);
          const startAngle = getScoreDotStartAngle(type, angle, index, originAngle, originRadius);
          const { x, y } = getCoords(startAngle, originRadius, cx, cy);
          return <circle key={startAngle} r={outerDotRad} transform={`translate(${x}, ${y})`} />;
        })}
      </g>
    </g>
  );
}

export default WheelOverlay;
