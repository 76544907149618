const TRANSITION_END_EVENT_NAMES = {
  transition: 'transitionend',
  OTransition: 'oTransitionEnd',
  MozTransition: 'transitionend',
  WebkitTransition: 'webkitTransitionEnd',
};

export function getTransitionEndEventName() {
  const node = document.createElement('div');

  for (let t in TRANSITION_END_EVENT_NAMES) {
    if (node.style[t] !== undefined) {
      return TRANSITION_END_EVENT_NAMES[t];
    }
  }
}
