class TitleBarBehaviour {
  static id = 'title-bar';

  constructor(node) {
    if (!('IntersectionObserver' in window) || !node.dataset.subject) {
      return;
    }

    this.node = node;
    this.subjectNode = document.querySelector(node.dataset.subject);
    const threshold = node.dataset.threshold || 0.5;

    this.node.addEventListener('transitionend', this.handleVisibility);

    const observer = new IntersectionObserver(
      (changes) => {
        // XXX: isIntersecting seems to be incorrect in some browser so just check our threshold
        // directly against the intersectionRatio
        if (!changes.length || changes[0].intersectionRatio >= threshold) {
          this.node.classList.remove('sticky');
        } else {
          this.node.classList.add('sticky');
        }
      },
      { threshold: threshold }
    );

    observer.observe(this.subjectNode);
  }

  handleVisibility = () => {
    if (this.node.classList.contains('sticky')) {
      this.node.classList.remove('title-bar--hidden');
    } else {
      this.node.classList.add('title-bar--hidden');
    }
  };
}

export default TitleBarBehaviour;
