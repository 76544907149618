import React, { useEffect, useReducer, useRef } from 'react';
import clsx from 'clsx';

import Select from 'components/Select';
import FormMessage from 'components/FormMessage';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import Textarea from 'components/Textarea';
import TextInput from 'components/TextInput';

import { fetchJSON } from '../../utils/fetch.js';

import reducer, { setInitialState, resetErrors } from './reducer';
import {
  setInputValueAction,
  resetAllValuesAction,
  putErrorsAction,
  setFormStateAction,
  setFormLoadingAction,
} from './actions';
import validate from './helpers/validate';

import './styles.scss';

export default function FeedbackModalContainer({
  onClose,
  countries,
  policies,
  csrfTokenName,
  csrfTokenValue,
  country,
  policy,
}) {
  const SELECT_DEFAULT = 'Select…';
  const [state, dispatch] = useReducer(reducer, { country, policy }, setInitialState);
  const { errors, loading, formState, inputs } = state;
  const focusedNode = useRef(null);
  const autoFocusInputNode = useRef(null);

  // XXX: We only want to run the hook on mount, so dependencies are omitted
  useEffect(() => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.classList.add('body__modal--active');
    focusedNode.current = document.activeElement;
    autoFocusInputNode.current && autoFocusInputNode.current.focus();
    return () => {
      const scrollY = document.body.style.top;
      document.body.classList.remove('body__modal--active');
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      focusedNode.current && focusedNode.current.focus();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFields = () => dispatch(resetAllValuesAction());

  const submitForm = async (node) => {
    dispatch(setFormLoadingAction(true));

    const errors = {
      fromName: !validate('text', inputs.fromName),
      fromEmail: !validate('email', inputs.fromEmail),
      message: !validate('textarea', inputs['message[body]']),
    };

    const valid = Object.keys(errors).every((key) => !errors[key]);

    if (valid) {
      dispatch(putErrorsAction(resetErrors()));
    } else {
      dispatch(putErrorsAction(errors));
      dispatch(setFormLoadingAction(false));
      return;
    }

    try {
      const data = new FormData(node);
      data.append('action', 'contact-form/send');
      if (csrfTokenName && csrfTokenValue) {
        data.append(csrfTokenName, csrfTokenValue);
      }
      await fetchJSON('/', 'POST', data);
      dispatch(setFormLoadingAction(false));
      resetFields();
      dispatch(setFormStateAction('success', __('feedback-success')));
    } catch (err) {
      dispatch(setFormLoadingAction(false));
      if (err.data) {
        dispatch(putErrorsAction(err.data));
      } else {
        dispatch(setFormStateAction('error', __('feedback-failure')));
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    submitForm(event.target);
  };

  const handleInputClearClick = (name) => dispatch(setInputValueAction(name, ''));

  const handleInput = (name, value) => dispatch(setInputValueAction(name, value));

  const handleSelect = (name, value) => dispatch(setInputValueAction(name, value));

  const handleKeyDown = (e) => {
    const ESCAPE = 27;
    if (e.keyCode === ESCAPE) {
      onClose();
    }
  };

  const countryTitles = countries.map((country) => country.title);
  const countryOptions = [__('feedback-country-multiple')].concat(countryTitles);
  const policyOptions = [[__('feedback-policy-multiple')]].concat(policies);

  return (
    <div
      className={`feedback-modal ${!onClose ? 'feedback-modal--solid' : ''}`}
      onKeyDown={handleKeyDown}
    >
      <div className="feedback">
        <header className="feedback__header">
          <h1>{__('feedback-title')}</h1>
          {onClose && (
            <button className="feedback__close" onClick={onClose} type="button">
              <Icon id="close-modal" />
            </button>
          )}
        </header>
        <div
          className={clsx(
            'feedback__content',
            loading && 'feedback__content--loading',
            formState.status && 'feedback__content--reponse'
          )}
        >
          <p className="t-small-body">{__('feedback-intro')}</p>
          {!loading && !formState.status && (
            <form className="feedback__form" method="post" action="" onSubmit={onSubmit}>
              <TextInput
                className="feedback__input feedback__input--name"
                errorMessage={errors.fromName && __('feedback-firstname-error')}
                label={__('feedback-firstname')}
                name="fromName"
                onClickClear={handleInputClearClick}
                onInput={handleInput}
                value={inputs.fromName}
                required
                ref={autoFocusInputNode}
              />
              <TextInput
                className="feedback__input feedback__input--email"
                errorMessage={errors.fromEmail && __('feedback-email-error')}
                label={__('feedback-email')}
                name="fromEmail"
                onClickClear={handleInputClearClick}
                onInput={handleInput}
                value={inputs.fromEmail}
                required
              />
              <TextInput
                className="feedback__input feedback__input--twitter"
                label={__('feedback-twitter')}
                name="message[Twitter]"
                onClickClear={handleInputClearClick}
                onInput={handleInput}
                value={inputs['message[Twitter]']}
              />
              <TextInput
                className="feedback__input feedback__input--org"
                label={__('feedback-org')}
                name="message[Organisation]"
                onClickClear={handleInputClearClick}
                onInput={handleInput}
                value={inputs['message[Organisation]']}
              />
              <Select
                className="feedback__input feedback__input--country"
                label={__('feedback-country')}
                name="message[Country][]"
                options={countryOptions}
                defaultOption={SELECT_DEFAULT}
                onChange={handleSelect}
                value={inputs['message[Country][]']}
                autocomplete="off"
              />
              <Select
                className="feedback__input feedback__input--policy"
                label={__('feedback-policy')}
                name="message[Policy][]"
                options={policyOptions}
                nested
                defaultOption={SELECT_DEFAULT}
                onChange={handleSelect}
                value={inputs['message[Policy][]']}
                autocomplete="off"
              />
              {/* FIXME: Normalize string keys */}
              <Textarea
                className="feedback__input feedback__input--message"
                errorMessage={errors.message && __('feedback-comment-error')}
                label={__('feedback-tellus')}
                placeholder={__('feedback-tellus-placeholder')}
                name="message[body]"
                onClickClear={handleInputClearClick}
                onInput={handleInput}
                value={inputs['message[body]']}
                required
              />
              <div className="feedback__foot">
                <span>*Required</span>
                <button className="feedback__submit" type="submit">
                  {__('feedback-submit')}
                </button>
              </div>
            </form>
          )}
          {loading && (
            <div className="feedback__container">
              <Loader className="feedback__loader" />
            </div>
          )}
          {formState.status && (
            <div className="feedback__container">
              <FormMessage className="feedback__form-message" variant={formState.status}>
                {formState.reply}
              </FormMessage>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
