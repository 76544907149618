export function parseComponents() {
  const nodes = document.querySelectorAll('[data-component]');
  const components = [];
  for (let node of nodes) {
    let props = {};
    try {
      props = JSON.parse(node.querySelector('script').textContent);
    } catch (err) {}
    try {
      const loader = node.querySelector('.loader');
      loader.parentNode.removeChild(loader);
    } catch (err) {}
    const component = {
      node,
      id: node.dataset.component,
      props,
    };
    components.push(component);
  }
  return components;
}

export function initBehaviours(behaviours) {
  const nodes = document.querySelectorAll('[data-behaviour]');
  for (let node of nodes) {
    const behaviour = node.getAttribute('data-behaviour');
    if (behaviours[behaviour]) {
      new behaviours[behaviour](node);
    }
  }
}
