export default class TriggerHeaderBehaviour {
  static id = 'trigger-header';

  constructor(node) {
    this.node = node;
    this.headerNode = document.querySelector('.header');

    if (this.headerNode) {
      this.node.addEventListener('click', this.handleClick);
    }
  }

  // FIXME: Prevent dropdown behaviour from triggering at the same time?
  handleClick = () => {
    // FIXME: Get breakpoints from variable or add beacon
    if (window.innerWidth >= 980) {
      return;
    }

    this.headerNode.setAttribute('aria-expanded', 'true');
    this.headerNode.querySelector(`[data-ref=${this.node.getAttribute('data-target')}]`).click();
  };
}
