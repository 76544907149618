import React from 'react';

import './styles.scss';

import Icon from '../Icon';

export default function ErrorMessage({ children, className, ...restProps }) {
  return (
    <span className={`error-message ${className}`} {...restProps}>
      <Icon id="warning" />
      {children}
    </span>
  );
}
