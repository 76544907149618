/*
 *
 * Wheel chart constants
 *
 */
export const MAX_OUTER_DOT_RADIUS = 12;
export const MAX_INNER_DOT_RADIUS = 11;
export const MAX_SCORE_DOT_RADIUS = 8;
export const DOT_OVERLAP_REDUCTION = 2;
export const MIN_SCORE_DOT_RADIUS = 2;
export const DOT_OVERLAP_PERCENTAGE = 0.6;

export const LABEL_BREAKPOINT = 716;

export const CHART_WIDTH_MOBILE = 302;
export const CHART_WIDTH_DESKTOP = 718;

export const COUNTRY_COUNT_THRESHOLD = 26;

export const CHART_TYPE_ARC = 'arc';
export const CHART_TYPE_ROTATE = 'rotated';

//improvement: implement tokens file so this is hooked up to the styling?
export const TITLE_TEXT_MIN_WIDTH = 200;
export const TITLE_TEXT_PADDING_PC = 0.25 * 2;
