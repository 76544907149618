import { SET_INPUT_VALUE, SET_INTERACTION_VALUE, SET_ROTATE_VALUE } from './constants';

export const setInputValueAction = (inputName, value) => ({
  type: SET_INPUT_VALUE,
  value,
  inputName,
});

export const setRotateValueAction = (value) => ({
  type: SET_ROTATE_VALUE,
  value,
});

export const setInteractionValueAction = (key, value) => ({
  type: SET_INTERACTION_VALUE,
  key,
  value,
});
