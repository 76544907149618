import { openWindow } from 'utils/windows.js';

class ShareButtonsBehaviour {
  static id = 'share-buttons';

  constructor(node) {
    this.node = node;

    const url = window.location.href.replace(/#.+$/, '');

    this.updateURL(url);

    this.node.addEventListener('click', this.handleClick);
  }

  updateURL(url) {
    const encodedUrl = encodeURIComponent(url);
    const nodes = this.node.querySelectorAll('[data-ref]');

    for (let node of nodes) {
      let href = '';
      switch (node.dataset.ref) {
        case 'email': {
          const metaDescription = document.querySelector('meta[name="description"]');
          const description = metaDescription ? metaDescription.getAttribute('content') : null;
          const body = description
            ? encodeURIComponent(`${description}\r\n\r\n${url}`)
            : encodedUrl;
          href = `mailto:?body=${body}`;
          break;
        }
        case 'facebook':
          href = 'http://www.facebook.com/sharer.php?u=' + encodedUrl;
          break;
        case 'linkedin':
          href = 'https://www.linkedin.com/shareArticle?mini=true&url=' + encodedUrl;
          break;
        case 'twitter':
          href = 'https://twitter.com/intent/tweet?url=' + encodedUrl;
          break;
        case 'whatsapp':
          href = 'https://wa.me/?text=' + encodedUrl;
          break;
      }
      node.setAttribute('href', href);
    }
  }

  handleClick = (event) => {
    const anchor = event.target.closest('a');

    if (!anchor || anchor.protocol === 'mailto:') {
      return;
    }

    event.preventDefault();
    openWindow(anchor.href);
  };
}

export default ShareButtonsBehaviour;
