class HashScrollTargetBehaviour {
  static id = 'hash-scroll-target';

  constructor(node) {
    window.addEventListener(
      'hashchange',
      () => {
        const targetName = window.location.hash.replace('#', '');
        const targetNode = document.querySelector(`a[name='${targetName}']`);
        if (node.contains(targetNode)) {
          try {
            node.parentNode.parentNode.scrollIntoView(true);
          } catch (err) {}
        }
      },
      false
    );
  }
}

export default HashScrollTargetBehaviour;
