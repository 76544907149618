/* eslint-disable no-undef */
import React from 'react';
import { createPortal } from 'react-dom';

import PolicyScoresContainer from '../containers/PolicyScores';
import CountriesGraphicContainer from '../containers/CountriesGraphic';
import FeedbackModalContainer from '../containers/FeedbackModal';

import behaviours from '@components/behaviours.js';

import { parseComponents, initBehaviours } from '../utils/components.js';
import bridge from '../utils/bridge.js';
import '../utils/googleTranslateHack.js';

import './index.scss';

const COMPONENTS = {
  PolicyScoresContainer: PolicyScoresContainer,
  FeedbackModalContainer: FeedbackModalContainer,
  CountriesGraphicContainer: CountriesGraphicContainer,
};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      showFeedbackModal: false,
      feedbackModalData: null,
    };
  }

  componentDidMount() {
    initBehaviours(behaviours);

    if (process.env.NODE_ENV === 'development') {
      window.setTimeout(() => {
        const components = parseComponents();
        this.setState({ components });
      }, 2000);
    } else {
      const components = parseComponents();
      this.setState({ components });
    }

    bridge.on('feedback:open', (data) => {
      this.setState({ showFeedbackModal: true, feedbackModalData: data });
    });
  }

  render() {
    return (
      <>
        {this.state.components.map((component) => {
          const Constructor = COMPONENTS[component.id];
          if (Constructor) {
            return createPortal(<Constructor {...component.props} />, component.node);
          }
        })}
        {this.state.showFeedbackModal && (
          <FeedbackModalContainer
            onClose={() =>
              this.setState({
                showFeedbackModal: false,
              })
            }
            csrfTokenName={__ENV__.csrfTokenName}
            csrfTokenValue={__ENV__.csrfTokenValue}
            countries={__ENV__.countries}
            policies={__ENV__.policies}
            policy={this.state.feedbackModalData.policy}
            country={this.state.feedbackModalData.country}
          />
        )}
      </>
    );
  }
}
