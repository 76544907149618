class HeaderBehaviour {
  static id = 'header';

  constructor(node) {
    this.node = node;
    this.triggerNode = node.querySelector('[data-ref="trigger"]');
    this.triggerNode.addEventListener('click', this.handleClickTrigger);
  }

  toggle(open) {
    if (open === undefined) open = !(this.node.getAttribute('aria-expanded') === 'true');
    this.node.setAttribute('aria-expanded', open ? 'true' : 'false');
  }

  handleClickTrigger = (event) => {
    this.toggle();
  };
}

export default HeaderBehaviour;
