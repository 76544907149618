export default class CountryReadmore {
  static id = 'country-readmore';

  constructor(node) {
    this.node = node;
    this.triggerNode = document.querySelectorAll('[data-ref="readmore-trigger"]');
    this.contentNode = document.querySelector('[data-ref="readmore-content"]');

    if (!this.contentNode) {
      return;
    }

    this.contentNode.addEventListener('focusin', this.handleFocusInContent);
    this.triggerNode.forEach((x) => x.addEventListener('click', this.handleClickTrigger));

    window.addEventListener('hashchange', this.handleHashChange);

    // handle routing with hash
    const hash = window.location.hash.slice(1);
    const footnote = document.querySelector(`[data-ref="readmore-content"] [name="${hash}"]`);
    if (hash && footnote) {
      this.animateOpen();
    }
  }

  handleHashChange = (e) => {
    const hash = e.target.location.hash.slice(1);
    const footnote = document.querySelector(`[data-ref="readmore-content"] [name="${hash}"]`);
    if (footnote) {
      this.footnote = footnote;
      this.animateOpen();
    }
  };

  handleFocusInContent = () => this.animateOpen();

  handleClickTrigger = () => {
    const open = this.node.getAttribute('aria-expanded') === 'true';
    if (open) {
      this.animateClose();
    } else {
      this.animateOpen();
    }
  };

  animateClose = () => {
    requestAnimationFrame(() => {
      const contentHeight = this.contentNode.scrollHeight;
      this.contentNode.style.height = `${contentHeight}px`;
      requestAnimationFrame(() => {
        this.contentNode.style.height = '0px';
      });
    });

    this.node.setAttribute('aria-expanded', 'false');
  };

  animateOpen = () => {
    if (this.node.getAttribute('aria-expanded') === 'false') {
      const contentHeight = this.contentNode.scrollHeight;
      this.contentNode.style.height = `${contentHeight}px`;

      this.node.setAttribute('aria-expanded', 'true');

      this.contentNode.addEventListener('transitionend', this.handleTransEnd);
    }
  };

  handleTransEnd = () => {
    this.contentNode.style.height = 'auto';
    if (this.footnote) {
      this.footnote.scrollIntoView(true);
    }
    this.footnote = null;
    this.contentNode.removeEventListener('transitionend', this.handleTransEnd);
  };
}
