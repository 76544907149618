import React from 'react';
import { render } from 'react-dom';
import elementClosest from 'element-closest';

// TODO: Load focus visible asynchronously
import 'focus-visible';
// TODO: Load picturefill conditionally (check for srcset and sizes attribute)
import 'picturefill';
import 'lazysizes';

import './styles.js';

import App from './app/index.js';

elementClosest(window);

const node = document.createElement('div');
document.body.appendChild(node);

render(<App />, node);
