import React from 'react';

import WorldMap from 'components/WorldMap';

export default function PolicyScoresContainer({ data }) {
  return (
    <>
      <WorldMap data={data} />
    </>
  );
}
