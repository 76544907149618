import React, { memo } from 'react';
import clsx from 'clsx';

import './styles.scss';

import Icon from '../Icon';
import ScoreLabel from '../ScoreLabel';

import { CHART_TYPE_ROTATE } from '../constants';

function WheelLabel({ country, countryCode, href, badge, badgeLabel, policy, type, top }) {
  // const top = 180 * index < 90 || 180 * index >= 270;

  const LABEL_PADDING_TOP = type === CHART_TYPE_ROTATE ? 10 : 14;

  return (
    <div
      data-country={countryCode}
      className={clsx(
        'wheel__mobile-label',
        top && 'wheel__mobile-label--top',
        type && `wheel__mobile-label--${type}`
      )}
      style={{ top: `${top + LABEL_PADDING_TOP}px` }}
    >
      <div className="wheel__label-mobile-content">
        <a className="wheel__anchor" href={href}>
          {country}
        </a>
        <ScoreLabel text={badgeLabel} variant={badge} policy={policy} />
        <Icon className="wheel__label-icon" id="pointer-arrow" />
      </div>
    </div>
  );
}

export default memo(WheelLabel);
