import React from 'react';
import clsx from 'clsx';

import './styles.scss';

function ScoreLabel({ text, variant, policy }) {
  const isScoreVariant = variant && /^-?\d$/.exec(variant.toString());
  return (
    <div
      className={clsx(
        'score-label',
        variant && `score-label--${isScoreVariant ? 'score' : variant.toLowerCase()}`
      )}
      data-policy={policy}
    >
      {text}
    </div>
  );
}

export default ScoreLabel;
