import bridge from 'utils/bridge.js';

class FeedbackButtonBehaviour {
  static id = 'feedback';

  constructor(node) {
    const policy = node.dataset.policy;
    const country = node.dataset.country;

    node.addEventListener('click', function(event) {
      if (event.target.matches('a')) {
        event.preventDefault();
      }

      bridge.emit('feedback:open', { policy, country });
    });
  }
}

export default FeedbackButtonBehaviour;
