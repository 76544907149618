import React, { memo } from 'react';
import { geoPath, geoNaturalEarth1 } from 'd3-geo';
import { SizeMe } from 'react-sizeme';
import clsx from 'clsx';

import './styles.scss';

import { feature as topojsonFeature } from 'topojson-client';
import world from 'sane-topojson/dist/world_110m.json';

const countries = topojsonFeature(world, world.objects.countries);
countries.features = countries.features.filter((feature) => feature.id && feature.id !== 'ATA');

const PROJECTION_ROTATION = [-11.5, 0, 0];

export function WorldMap({ data, width = 512, height = 512 }) {
  // console.log(data);
  const projection = geoNaturalEarth1()
    .fitSize([width, height], countries)
    .rotate(PROJECTION_ROTATION);
  const path = geoPath().projection(projection);

  return (
    <svg className="world-map" viewBox={`0 0 ${width} ${height}`}>
      <g>
        {countries.features.map((country) => {
          const id = country.id || country.properties.iso_a3;
          const available = data.includes(id);
          return (
            <path
              key={id}
              className={clsx('world-map__country', available && 'world-map__country--available')}
              data-iso={id}
              d={path(country.geometry)}
              data-available={available}
            />
          );
        })}
      </g>
    </svg>
  );
}

export default memo(WorldMap);
