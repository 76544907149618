export function openWindow(url, w, h) {
  w = w || 550;
  h = h || 350;
  var sw = window.screen.width * (window.devicePixelRatio || 1);
  var sh = window.screen.height * (window.devicePixelRatio || 1);
  var x = sw / 2 - w / 2;
  var y = sh / 4 - h / 2;

  window.open(
    url,
    'share',
    `height=${h},width=${w},top=${y},left=${x},toolbar=0,location=0,menubar=0,directories=0,scrollbars=0`
  );
}
