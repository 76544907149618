export default function(fieldType, value) {
  if (value == null) {
    return false;
  }
  switch (fieldType) {
    case 'email':
      return value.length > 0 && value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    case 'text':
      return value.length > 0;
    case 'select':
      return value && value != 'Select…';
    case 'textarea':
      return value.length >= 20;
    default:
      return false;
  }
}
