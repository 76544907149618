import React from 'react';
import clsx from 'clsx';

import Icon from '../Icon';

import './styles.scss';

function Select({
  className,
  defaultOption,
  label,
  onChange,
  options,
  nested,
  required,
  value,
  ...restProps
}) {
  const handleChange = (e) => onChange(e.target.getAttribute('name'), e.target.value);
  const facadeValue = value === '' && defaultOption ? defaultOption : value;

  return (
    <label className={clsx('select', className)}>
      {required ? `${label} *` : label}
      <div className="select__wrapper">
        <select
          aria-label={label}
          className="select__select"
          onChange={handleChange}
          value={value}
          {...restProps}
        >
          {defaultOption && (
            <option key="default" value="" disabled selected={!value}>
              {defaultOption}
            </option>
          )}
          {options.map((option) =>
            !nested || !option[1] ? (
              <option key={option} value={option}>
                {option}
              </option>
            ) : (
              <optgroup key={option[0]} label={option[0]}>
                {option[1].length ? (
                  option[1].map((child) => (
                    <option key={child} value={child}>
                      {child}
                    </option>
                  ))
                ) : (
                  <option key={option[0]} value={option[0]}>
                    {option}
                  </option>
                )}
              </optgroup>
            )
          )}
        </select>
        <div
          className={clsx(
            'select__value',
            value === '' && defaultOption && 'select__value--default'
          )}
          aria-hidden="true"
        >
          <span key={facadeValue}>{facadeValue}</span>
          <Icon id="chevron" />
        </div>
      </div>
    </label>
  );
}

export default Select;
