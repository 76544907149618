import React, { memo } from 'react';
import clsx from 'clsx';

import './styles.scss';

import Icon from '../Icon';

const MARKER_PADDING_TOP = 16;
const SVG_DOT_RADIUS = 2;

function SortMarker({ label, radius, rotation = 0 }) {
  const width = (radius - MARKER_PADDING_TOP) * 2;
  //get pixel offset based on radius to position marker correctly
  const svgOffset = (SVG_DOT_RADIUS / 221) * (radius * 2);
  return (
    <div
      className={clsx('sort-marker')}
      style={{
        width: `${width}px`,
        height: `${width}px`,
        transform: `translate(-50%,-50%) rotate(${rotation}deg)`,
      }}
    >
      <Icon
        className="sort-marker__icon"
        id="sort-marker-small"
        style={{
          transform: `translate(${-svgOffset}px,${-svgOffset}px)`,
        }}
      />
      <span
        style={{
          top: `${SVG_DOT_RADIUS * 2 + 8}px`,
        }}
      >
        {label}
      </span>
    </div>
  );
}

export default memo(SortMarker);
