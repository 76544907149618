class WheelKeyBehaviour {
  static id = 'wheel-key';

  constructor(node) {
    this.node = node;
    this.triggerNode = node.querySelector('[data-ref="trigger"]');
    this.mainNode = this.triggerNode.nextElementSibling;

    this.triggerNode.addEventListener('click', this.handleClickTrigger);
  }

  toggle(open) {
    if (open === undefined) {
      open = !(this.triggerNode.getAttribute('aria-expanded') === 'true');
    }
    this.triggerNode.setAttribute('aria-expanded', open ? 'true' : 'false');
    if (open) {
      this.mainNode.removeAttribute('hidden');
    } else {
      this.mainNode.setAttribute('hidden', true);
    }
  }

  handleClickTrigger = () => {
    this.toggle();
  };
}

export default WheelKeyBehaviour;
