import React from 'react';
import clsx from 'clsx';

import './styles.scss';

function Loader({ className }) {
  return (
    <div className={clsx('loader', className)}>
      <div className="loader__main">
        <div className="loader__clipper loader__clipper--left">
          <div className="loader__clipper__circle"></div>
        </div>
        <div className="loader__clipper loader__clipper--right">
          <div className="loader__clipper__circle"></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
