import fetch from 'unfetch';

export async function fetchJSON(url, method, payload) {
  const response = await fetch(url, {
    method: method,
    headers: {
      Accept: 'application/json',
    },
    body: payload,
    credentials: 'same-origin',
  });
  if (response.status !== 200) {
    throw new Error();
  }
  const data = await response.json();
  if (!data.success) {
    const validationError = new Error();
    validationError.data = data.errors;
    throw validationError;
  }
}
