import React, { useReducer } from 'react';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';

import WheelChart from 'components/WheelChart';
import Select from 'components/Select';

import reducer, { setInitialState, resetRotateValues } from './reducer';
import { setInputValueAction, setRotateValueAction, setInteractionValueAction } from './actions';

const countryGroupLookup = {
  'GEC hubs': 'hub',
};

const filteringDetails = {
  Region: {
    datum: 'country.region',
    // need feedback from client on how to handle this sort now extra countries are in play, figure out way to reorder
    useInSort: true,
    reverse: true,
  },
  Income: {
    datum: 'income.group',
  },
  Group: {
    uniqueKeys: true,
    datum: (group) => countryGroupLookup[group],
  },
};

export default function PolicyScoresContainer({ data, theme, regions, incomeGroups }) {
  // set information for sorting/filtering with nuance of those that are group sorted, which data key to sort by and order

  const sortingDetails = {
    Region: {
      group: {
        ...filteringDetails['Region'],
        keys: regions.sort(),
      },
      sortDatum: 'country.title',
      sortLabel: 'Region',
    },
    Income: {
      group: {
        ...filteringDetails['Income'],
        keys: incomeGroups,
      },
      sortDatum: 'income.value',
      sortLabel: 'Highest',
      reverse: true,
    },
    Alphabetical: {
      sortDatum: 'country.title',
      sortLabel: 'A-Z',
    },
    Score: {
      sortDatum: 'score',
      reverse: true,
      sortLabel: 'Highest',
    },
    'Last updated': {
      sortDatum: 'lastUpdated',
      reverse: true,
      sortLabel: 'Latest',
    },
  };

  const sortOptions = Object.keys(sortingDetails);

  // create grouped arrays for select dropdown
  // add label / value detail to selects and state here? (feedback modal also uses select component)
  const regionOptions = ['Region', regions.sort()];
  const incomeOptions = ['Income', incomeGroups];
  const groupOptions = ['Group', ['GEC hubs']];
  const filterOptions = [['All'], regionOptions, incomeOptions, groupOptions];

  const [state, dispatch] = useReducer(
    reducer,
    { filter: filterOptions[0], sort: sortOptions[0] },
    setInitialState
  );

  const onInputChange = (name, value) => dispatch(setInputValueAction(name, value));
  const onRotateChange = (values) => dispatch(setRotateValueAction(values));
  const onInteractionChange = (key, value) => dispatch(setInteractionValueAction(key, value));

  const { inputs, rotateAngles, interaction } = state;
  const { filter, sort } = inputs;

  // remove all from options so it doesn't try to sort
  const filterGroups = filterOptions.slice(1);

  // return group that includes selected filter
  const filterArray = filterGroups.filter((array) => {
    if (array[1]) {
      return array[1].includes(filter);
    }
  });

  // get selected filter group key
  const filterGroup = filterArray.length ? filterArray[0][0] : null;

  // filter data if group key exists
  const filteredData = filterGroup
    ? data.filter((item) => {
        const groupDetails = filteringDetails[filterGroup];
        if (groupDetails.uniqueKeys) {
          const filterKey = groupDetails.datum(filter);
          return item[filterKey] === true;
        }
        const filterKey = groupDetails.datum.split('.');
        return filter === item[filterKey[0]][filterKey[1]];
      })
    : data;

  // not sure this is the most efficient way for sorting, open to suggestions on how to improve, was a bit tricky due to grouped or not grouped aspect
  // also need to fix zeros scores being ordered first when sorted by score

  const { group, sortDatum, reverse } = sortingDetails[sort];

  let sortedData;

  if (group && group.useInSort) {
    const groupedData = groupBy(filteredData, group.datum);
    const groups = group.reverse ? group.keys.slice(0).reverse() : group.keys;
    sortedData = groups.reduce((memo, key) => {
      const groupData = sortBy(groupedData[key], sortDatum);
      return memo.concat(groupData);
    }, []);
  } else {
    sortedData = sortBy(filteredData, sortDatum);
    if (reverse) {
      sortedData.reverse();
    }
  }

  const processedData = sortedData.map((datum) => {
    return {
      // FIXME: Wheel chart expect a code field to use as the key when rendering data items, but we
      // don't have the country ISO code here
      code: datum.country.title,
      country: datum.country.title,
      href: datum.country.url,
      score: parseInt(datum.score, 10),
      status: datum.status,
      revised: datum.revised,
    };
  });

  const handleSelect = (name, value) => {
    onInputChange(name, value);
    onRotateChange(resetRotateValues());
  };

  const countriesTotal = data.length;
  const countriesCount = processedData.length;

  const activeSortLabel = sortingDetails[sort].sortLabel;

  return (
    <>
      <div className="policy__dropdowns">
        <Select
          className="policy__filter"
          label="Filter countries"
          name="filter"
          nested
          value={filter}
          options={filterOptions}
          onChange={handleSelect}
          autocomplete="off"
        />
        <Select
          className="policy__sort"
          label="Sort by"
          name="sort"
          value={sort}
          options={sortOptions}
          onChange={handleSelect}
          autocomplete="off"
        />
        <div
          key={`${countriesCount}-${countriesTotal}`}
          className="policy__country-counts policy__country-counts--desktop"
        >
          Displaying {countriesCount} of {countriesTotal} total countries, each country is given a
          score of 1-5 for the policy.
        </div>
        <div
          key={`${countriesCount}-${countriesTotal}-mobile`}
          className="policy__country-counts policy__country-counts--mobile"
        >
          {countriesCount}/{countriesTotal} countries
        </div>
      </div>
      <div className="policy__graphic">
        <div className="policy__graphic__layer">
          <WheelChart
            data={processedData}
            policy={theme}
            rotateState={rotateAngles}
            interactionState={interaction}
            onRotate={onRotateChange}
            onInteraction={onInteractionChange}
            sortLabel={activeSortLabel}
          />
        </div>
      </div>
    </>
  );
}
