import elementResizeDetectorMaker from 'element-resize-detector';
import fetch from 'unfetch';

import { getTransitionEndEventName } from 'utils/animation.js';

const STATE_IDLE = 'idle';
const STATE_LOADING = 'loading';
const FRICTION = 0.3;
const SNAP_TRESHOLD = 320 / 3;
const FETCH_COUNT = 4;
const TRANSITION_END_EVENT_NAME = getTransitionEndEventName();
const TRANSITION_DURATION_MS = 350;

export default class Carousel {
  static id = 'carousel';

  constructor(node) {
    this.node = node;

    this.viewportNode = document.querySelector('[data-ref="viewport"]');
    this.itemsNode = document.querySelector('[data-ref="carousel-items"]');
    this.itemNode = this.itemsNode.firstElementChild;
    this.prevButtonNode = document.querySelector('[data-ref="carousel-prev"]');
    this.nextButtonNode = document.querySelector('[data-ref="carousel-next"]');

    this.viewportNode.addEventListener('mousedown', this.handleDragStart);
    this.viewportNode.addEventListener('touchstart', this.handleDragStart, { passive: true });

    this.prevButtonNode.addEventListener('click', this.handlePrevClick);
    this.nextButtonNode.addEventListener('click', this.handleNextClick);

    const resizeDetector = elementResizeDetectorMaker({ strategy: 'scroll' });
    resizeDetector.listenTo(this.viewportNode, this.handleViewportResize);

    this.activeIndex = 0;
    this.max = this.node.dataset.max || Infinity;

    this.itemsLength = this.itemsNode.childElementCount;
    this.viewportLength = 1;
    this.carouselLength = this.itemsLength - this.viewportLength;

    this.currentX = 0;
    this.dragOriginX = null;
    this.dragX = null;
    this.dragDeltaX = 0;

    this.url = this.node.dataset.url;

    this.animating = false;
    this.state = STATE_IDLE;
  }

  handleViewportResize = () => {
    this.calculateBounds();
    this.updateControls();
    this.jump(this.activeIndex);
  };

  handleDragStart = (e) => {
    if (this.animating) {
      return;
    }

    if (e.type === 'touchstart') {
      this.dragOriginX = this.dragX = e.touches[0].clientX;
      this.viewportNode.addEventListener('touchmove', this.handleDragMove, { passive: true });
      this.viewportNode.addEventListener('touchend', this.handleDragEnd, { passive: true });
      this.viewportNode.addEventListener('touchcancel', this.handleDragEnd, { passive: true });
    } else {
      if (e.button !== 0) {
        return;
      }

      this.dragOriginX = this.dragX = e.clientX;
      document.documentElement.addEventListener('mousemove', this.handleDragMove);
      document.documentElement.addEventListener('mouseup', this.handleDragEnd);
    }
  };

  handleDragMove = (e) => {
    if (this.animating) {
      return;
    }

    e.preventDefault();
    const x = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
    const deltaX = x - this.dragOriginX;
    const newX = this.currentX + deltaX * FRICTION;
    this.dragX = newX;
    this.dragDeltaX = deltaX;
    this.setTransform(newX);
  };

  handleDragEnd = (e) => {
    const absDeltaX = Math.abs(this.dragDeltaX);

    if (absDeltaX !== 0) {
      if (absDeltaX > SNAP_TRESHOLD) {
        const d = this.dragDeltaX / absDeltaX;
        this.snap(this.activeIndex + d);
      } else {
        this.snap(this.activeIndex);
      }
    }

    this.dragOriginX = this.dragX = null;
    this.dragDeltaX = 0;

    if (e.type === 'touchend' || e.type === 'touchcancel') {
      this.viewportNode.removeEventListener('touchmove', this.handleDragMove);
      this.viewportNode.removeEventListener('touchend', this.handleDragEnd);
      this.viewportNode.removeEventListener('touchcancel', this.handleDragEnd);
    } else {
      document.documentElement.removeEventListener('mousemove', this.handleDragMove);
      document.documentElement.removeEventListener('mouseup', this.handleDragEnd);
    }
  };

  handleNextClick = () => {
    this.snap(this.activeIndex - (this.viewportLength || 1));
  };

  handlePrevClick = () => {
    this.snap(this.activeIndex + (this.viewportLength || 1));
  };

  calculateBounds = () => {
    const itemRect = this.itemNode.getBoundingClientRect();
    const viewportRect = this.viewportNode.getBoundingClientRect();
    const viewportLength = Math.round(viewportRect.width / itemRect.width);

    this.itemsLength = this.itemsNode.childElementCount;
    this.viewportLength = viewportLength;
    this.carouselLength = this.itemsLength - viewportLength;

    // TODO: Check if this condition can ever occur
    if (this.activeIndex > this.carouselLength) {
      this.activeIndex = this.carouselLength;
    }

    this.maybeLoadItems();
  };

  setTransform = (x) => {
    this.itemsNode.style.transform = `translateX(${x}px)`;
  };

  jump = (n) => {
    const constrainedN = Math.max(Math.min(n, this.carouselLength), 0);
    const itemRect = this.itemNode.getBoundingClientRect();
    const x = constrainedN * itemRect.width;

    this.setTransform(x);
    this.currentX = x;
    this.activeIndex = constrainedN;
  };

  snap = (n) => {
    const constrainedN = Math.max(Math.min(n, this.carouselLength), 0);
    const itemRect = this.itemNode.getBoundingClientRect();
    const x = constrainedN * itemRect.width;

    const endTransition = () => {
      this.itemsNode.style.transition = null;
      this.itemsNode.removeEventListener(TRANSITION_END_EVENT_NAME, endTransition);
      this.currentX = x;
      this.animating = false;
    };

    requestAnimationFrame(() => {
      this.animating = true;
      this.itemsNode.style.transition = `transform ${TRANSITION_DURATION_MS}ms`;
      this.setTransform(x);
      this.itemsNode.addEventListener(TRANSITION_END_EVENT_NAME, endTransition);
    });

    this.activeIndex = constrainedN;

    this.updateControls();
    this.maybeLoadItems();
  };

  updateControls = () => {
    this.nextButtonNode.disabled = this.activeIndex === 0;
    this.prevButtonNode.disabled = this.activeIndex >= this.carouselLength;

    this.node.dataset.state = this.state;
  };

  maybeLoadItems = () => {
    if (!this.url) {
      return;
    }

    if (this.state === STATE_LOADING) {
      return;
    }

    if (this.activeIndex !== this.carouselLength) {
      return;
    }

    if (this.max && this.itemsLength >= this.max) {
      return;
    }

    this.state = STATE_LOADING;

    this.updateControls();

    fetch(`${this.url}?count=${FETCH_COUNT}&offset=${this.itemsLength}`)
      .then((response) => response.text())
      .then((html) => {
        this.itemsNode.insertAdjacentHTML('beforeend', html);
        this.calculateBounds();
        this.state = STATE_IDLE;
        this.updateControls();
      });
  };
}
