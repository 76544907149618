export default class HomepageAnimation {
  static id = 'homepage-animation';

  constructor(node) {
    this.node = node;
    this.loadedImages = [];

    if (window.navigator.userAgent.match(/(MSIE|Trident)/)) {
      this.node.classList.add('homepage-animation--ie');
    }

    this.imageNodes = this.node.querySelectorAll('[data-ref="images"] > img');

    const firstImageNode = this.node.querySelector('[data-ref="placeholder"] > img');

    if (!firstImageNode.complete) {
      firstImageNode.addEventListener('load', this.handleFirstImageLoaded);
    } else {
      this.handleFirstImageLoaded();
    }
  }

  handleFirstImageLoaded = () => {
    this.loadedImages.push(true);
    this.loadImages();
  };

  loadImages = () => {
    [...this.imageNodes].forEach((image) => {
      const dataSrc = image.getAttribute('data-src');
      const dataSrcSet = image.getAttribute('data-srcset');

      if (dataSrc && dataSrcSet) {
        image.addEventListener('load', this.handleImageLoaded);
        // XXX: srcset attribute must be set first to avoid double loading in Safari
        image.setAttribute('srcset', dataSrcSet);
        image.setAttribute('src', dataSrc);

        try {
          // eslint-disable-next-line no-undef
          picturefill({
            elements: [image],
          });
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    });
  };

  handleImageLoaded = () => {
    this.loadedImages.push(true);
    this.maybeStartAnimation();
  };

  maybeStartAnimation = () => {
    if (this.loadedImages.length === this.imageNodes.length) {
      // XXX: this timeout helps the loading and playing on iOS12
      setTimeout(() => this.node.classList.remove('homepage-animation--loading'), 2000);
    }
  };
}
