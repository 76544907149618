import React, { memo, createRef } from 'react';
import clsx from 'clsx';
import { SizeMe } from 'react-sizeme';

import ScoreBar from '../ScoreBar';
import WheelLabel from '../WheelLabel';
import SortMarker from '../SortMarker';
import WheelMobileLabel from '../WheelMobileLabel';
import WheelOverlay from '../WheelOverlay';

import { radiansToDegrees } from 'utils/math.js';
import {
  getSections,
  getFocusCountries,
  getPositionFromCenter,
  findClosestSegment,
  getInnerRadius,
  getDotRadii,
  getArcLength,
  getScoreLength,
  getScoreSegment,
} from './helpers';
import {
  LABEL_BREAKPOINT,
  COUNTRY_COUNT_THRESHOLD,
  CHART_TYPE_ARC,
  CHART_TYPE_ROTATE,
} from '../constants';

import './styles.scss';

class WheelChart extends React.Component {
  constructor(props) {
    super(props);

    this._rootRef = createRef();
  }

  handleDragStart = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const { rotateState, interactionState, onRotate, onInteraction } = this.props;
    const { x, y } = getPositionFromCenter(e, this._rootRef.current);
    const newStartAngle = 90 - radiansToDegrees(Math.atan2(y, x));

    this._rootRef.current.style.transition = 'none';

    onInteraction({ ...interactionState, dragging: true });
    onRotate({ ...rotateState, startAngle: newStartAngle });

    if (e.type === 'touchstart') {
      document.addEventListener('touchmove', this.handleDragMove);
      document.addEventListener('touchend', this.handleDragEnd);
      document.addEventListener('touchcancel', this.handleDragEnd);
    } else {
      document.addEventListener('mousemove', this.handleDragMove);
      document.addEventListener('mouseup', this.handleDragEnd);
    }
  };

  handleDragMove = (e) => {
    const { rotateState, onRotate, interactionState } = this.props;
    const { dragging } = interactionState;

    if (!dragging && this.props.width >= LABEL_BREAKPOINT) {
      return;
    }

    const { currentAngle, startAngle } = rotateState;
    const { x, y } = getPositionFromCenter(e, this._rootRef.current);
    const newAngle = 90 - radiansToDegrees(Math.atan2(y, x));

    const indexAngle = findClosestSegment(
      +(currentAngle + (newAngle - startAngle)),
      this.angleSegment
    );

    onRotate({
      ...rotateState,
      angle: newAngle,
      indexAngle: indexAngle,
      rotate: currentAngle + (newAngle - startAngle),
    });
  };

  handleDragEnd = (e) => {
    if (this.props.width >= LABEL_BREAKPOINT) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const { rotateState, interactionState, onRotate, onInteraction } = this.props;
    const { currentAngle, angle, startAngle } = rotateState;

    let rotateAngle = angle;
    if (rotateAngle === 0 && startAngle >= 90 && startAngle < 270) {
      rotateAngle = 180;
    }

    const newCurrentAngle = findClosestSegment(
      +(currentAngle + (rotateAngle - startAngle)),
      this.angleSegment
    );

    onInteraction({ ...interactionState, dragging: false });

    onRotate({
      ...rotateState,
      angle: 0,
      currentAngle: newCurrentAngle,
      indexAngle: newCurrentAngle,
      rotate: newCurrentAngle,
    });

    requestAnimationFrame(() => {
      this._rootRef.current.style.transition = 'transform 500ms';
    });

    if (e.type.indexOf('touch') === 0) {
      document.removeEventListener('touchmove', this.handleDragMove);
      document.removeEventListener('touchend', this.handleDragEnd);
      document.removeEventListener('touchcancel', this.handleDragEnd);
    } else {
      document.removeEventListener('mousemove', this.handleDragMove);
      document.removeEventListener('mouseup', this.handleDragEnd);
    }
  };

  render() {
    const {
      className,
      data,
      height,
      maxScore,
      policy,
      width,
      showCircleGuides = false,
      showScoreGuides = false,
      rotateState,
      sortLabel,
    } = this.props;
    const { indexAngle, rotate } = rotateState;

    this.type = data.length <= COUNTRY_COUNT_THRESHOLD ? CHART_TYPE_ARC : CHART_TYPE_ROTATE;

    this.angleSegment = 360 / data.length;

    this.CIRCLE_INSET = width >= LABEL_BREAKPOINT ? 76 : 0;
    this.LABEL_INSET = width >= LABEL_BREAKPOINT ? 64 : 0;

    const cx = width / 2;
    const radiusActual = width / 2;
    const circleRadius = radiusActual - this.CIRCLE_INSET;
    const outerLabelRadius = circleRadius + 6;
    const arcLength = getArcLength(this.angleSegment, circleRadius);
    const innerRadius = getInnerRadius(this.type, width, circleRadius, maxScore);

    const dotRadii = getDotRadii(this.type, arcLength, maxScore, innerRadius, circleRadius);
    const { outerDotRad, scoreDotRad } = dotRadii;

    const originRadius = innerRadius + outerDotRad;

    const focusCountries = getFocusCountries(indexAngle, data, this.angleSegment);

    const scoreSegment = getScoreSegment(
      this.angleSegment,
      circleRadius,
      2 * scoreDotRad * maxScore
    );

    const sections = getSections(this.type, maxScore, scoreSegment);

    const labelTop =
      this.type === CHART_TYPE_ROTATE ? getScoreLength(outerDotRad) * maxScore : outerDotRad;

    return (
      <div
        className={clsx('wheel-chart', this.type && `wheel-chart--${this.type}`, className)}
        data-policy={policy}
      >
        <svg
          className="wheel-chart__container"
          viewBox={`0 0 ${width} ${height}`}
          width={width}
          height={height}
          onMouseDown={this.handleDragStart}
          onTouchStart={this.handleDragStart}
          style={{ transform: `rotate(${rotate}deg)` }}
          ref={this._rootRef}
        >
          <g className="wheel-chart__data">
            <circle className="wheel-chart__back-circle" r={originRadius} cx={cx} cy={cx} />
            <g>
              <ScoreBar
                angle={this.angleSegment}
                arcLength={arcLength}
                cx={cx}
                cy={cx}
                data={data}
                maxScore={maxScore}
                radius={originRadius}
                dotRadii={dotRadii}
                sections={sections}
                type={this.type}
                labelRadius={outerLabelRadius}
                showGuides={showScoreGuides}
              />
            </g>
          </g>
        </svg>
        {width < LABEL_BREAKPOINT && (
          <svg
            style={{ pointerEvents: 'none', overflow: 'visible' }}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            height={height}
          >
            <WheelOverlay
              angle={this.angleSegment}
              angleStart={[0]}
              arcLength={arcLength}
              cx={cx}
              cy={cx}
              maxScore={maxScore}
              radius={originRadius}
              dotRadii={dotRadii}
              sections={sections}
              type={this.type}
            />
          </svg>
        )}
        <div className="wheel-chart__label-container">
          {width >= LABEL_BREAKPOINT ? (
            <>
              <SortMarker key={sortLabel} label={sortLabel} radius={innerRadius} rotate={rotate} />
              {data.map((datum, index) => {
                return (
                  <WheelLabel
                    key={datum.code}
                    countryCode={datum.code}
                    revised={datum.badge === 'revised'}
                    cx={cx}
                    cy={cx}
                    policy={policy}
                    radius={outerLabelRadius}
                    index={index}
                    angleSegment={this.angleSegment}
                    type={this.type}
                    {...datum}
                  />
                );
              })}
            </>
          ) : (
            focusCountries.map((datum, index) => (
              <WheelMobileLabel
                key={datum.code}
                countryCode={datum.code}
                index={index}
                policy={policy}
                type={this.type}
                top={labelTop}
                {...datum}
              />
            ))
          )}
        </div>
        {showCircleGuides && (
          <svg viewBox={`0 0 ${width} ${height}`} width={width} height={height}>
            <CircleGuide r={circleRadius} cx={cx} cy={cx} />
            <CircleGuide r={innerRadius} cx={cx} cy={cx} />
            <CircleGuide r={innerRadius - 16} cx={cx} cy={cx} />
            <CircleGuide r={outerLabelRadius} cx={cx} cy={cx} />
          </svg>
        )}
      </div>
    );
  }
}

WheelChart.defaultProps = {
  maxScore: 5,
};

const CircleGuide = ({ r, cx, cy }) => {
  return (
    <circle r={r} cx={cx} cy={cy} style={{ strokeWidth: 0.5, stroke: 'aqua', fill: 'none' }} />
  );
};

function SizedWheelChart(props) {
  return (
    <SizeMe monitorHeight>
      {({ size }) => (
        <div className="wheelchart">
          {size.width && size.height && (
            <div>
              <WheelChart width={size.width} height={size.height} {...props} />
            </div>
          )}
        </div>
      )}
    </SizeMe>
  );
}

export default memo(SizedWheelChart);
