import React, { useState } from 'react';
import clsx from 'clsx';

import './styles.scss';

import Icon from '../Icon';
import ErrorMessage from '../ErrorMessage';

function Textarea({
  className,
  errorMessage,
  label,
  onClickClear,
  onInput,
  placeholder,
  required,
  value,
  name,
  onBlur,
  ...restProps
}) {
  const [showClear, setShowClear] = useState(false);

  // event handlers
  const handleBlur = (e) => {
    if (!value) {
      setShowClear(false);
    } else {
      setShowClear(true);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleInput = (e) => onInput(e.target.getAttribute('name'), e.target.value);

  const handleClickClear = () => {
    setShowClear(false);
    onClickClear(name);
  };

  return (
    <div className={clsx('text-area__wrapper', className)}>
      <label className="text-area">
        {required ? `${label} *` : label}
        <div className="text-area__main">
          <textarea
            className={clsx('text-area__textarea', errorMessage && 'text-area__textarea--error')}
            onInput={handleInput}
            placeholder={placeholder}
            value={value}
            name={name}
            onBlur={handleBlur}
            {...restProps}
          ></textarea>
          <span />
          {value && showClear && (
            <button
              aria-label={`Clear ${label}`}
              className="text-area__button"
              type="button"
              onClick={handleClickClear}
            >
              <span />
              <Icon className="text-area__icon" id="close" />
            </button>
          )}
        </div>
      </label>
      {errorMessage && <ErrorMessage className="text-area__error">{errorMessage}</ErrorMessage>}
    </div>
  );
}

export default Textarea;
