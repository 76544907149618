import React, { useState } from 'react';
import clsx from 'clsx';

import Icon from '../Icon';
import ErrorMessage from '../ErrorMessage';

import './styles.scss';

function TextInput(
  {
    className,
    errorMessage,
    label,
    onBlur,
    onClickClear,
    onInput,
    placeholder,
    required,
    value,
    name,
    ...restProps
  },
  ref
) {
  const [showClear, setShowClear] = useState(false);

  // event handlers
  const handleBlur = (e) => {
    if (!value) {
      setShowClear(false);
    } else {
      setShowClear(true);
    }
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleInput = (e) => onInput(e.target.getAttribute('name'), e.target.value);

  const handleClickClear = () => {
    setShowClear(false);
    onClickClear(name);
  };

  return (
    <div className={clsx('text-input__wrapper', className)}>
      <label className="text-input" onInput={handleInput}>
        {required ? `${label} *` : label}
        <input
          className={clsx('text-input__input', errorMessage && 'text-input__input--error')}
          placeholder={placeholder}
          type="text"
          value={value}
          name={name}
          onBlur={handleBlur}
          ref={ref}
          {...restProps}
        />
        <span />
        {value && showClear ? (
          <button
            aria-label={`Clear ${label}`}
            className="text-input__button"
            type="button"
            onClick={handleClickClear}
          >
            <span />
            <Icon className="text-input__icon" id="close" />
          </button>
        ) : null}
      </label>
      {errorMessage ? (
        <ErrorMessage className="text-input__error">{errorMessage}</ErrorMessage>
      ) : null}
    </div>
  );
}

export default React.forwardRef(TextInput);
