export default class DropdownBehaviour {
  static id = 'dropdown';

  constructor(node) {
    this.node = node;
    this.triggerNode = node.querySelector('button[aria-expanded]');
    this.dropdownNode = this.triggerNode.nextElementSibling;

    this.triggerNode.addEventListener('click', this.handleClickTrigger);
  }

  toggle(open) {
    if (open === undefined) {
      open = !!this.dropdownNode.hidden;
    }

    this.dropdownNode.scrollTop = 0;
    this.triggerNode.setAttribute('aria-expanded', open ? 'true' : 'false');

    if (open) {
      this.dropdownNode.removeAttribute('hidden');
    } else {
      this.dropdownNode.setAttribute('hidden', true);
    }

    if (open) {
      this.layoutDropdown();
    } else {
      this.dropdownNode.setAttribute('data-flipped', false);
      this.dropdownNode.setAttribute('data-reflected', false);
    }

    if (open) {
      document.addEventListener('click', this.handleClickOutside, true);
      document.addEventListener('touchstart', this.handleClickOutside, true);
    } else {
      document.removeEventListener('click', this.handleClickOutside, true);
      document.removeEventListener('touchstart', this.handleClickOutside, true);
    }
  }

  layoutDropdown() {
    this.dropdownNode.style.visibility = 'hidden';
    this.dropdownNode.removeAttribute('data-flipped-x');
    this.dropdownNode.removeAttribute('data-flipped-y');

    const rect = this.dropdownNode.getBoundingClientRect();

    // TODO: Prefer unflipped version is not enough space in either direction?
    this.dropdownNode.setAttribute('data-flipped-x', rect.left + rect.width > window.innerWidth);
    this.dropdownNode.setAttribute('data-flipped-y', rect.top + rect.height > window.innerHeight);
    this.dropdownNode.style.visibility = 'visible';
  }

  handleClickTrigger = () => {
    this.toggle();
  };

  handleClickOutside = (event) => {
    if (
      event.target === this.triggerNode ||
      event.target === this.dropdownNode ||
      this.triggerNode.contains(event.target) ||
      this.dropdownNode.contains(event.target)
    ) {
      return;
    }

    this.toggle(false);
  };
}
