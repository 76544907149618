class ScoreTableBehaviour {
  static id = 'score-table';

  constructor(node) {
    this.node = node;
    this.rowNodes = node.querySelectorAll('[data-ref="row"]');
    this.triggerNodes = node.querySelectorAll('[data-ref="trigger"]');

    const KEY_ENTER = 13;

    this.triggerNodes.forEach((triggerNode) => {
      triggerNode.addEventListener('click', this.handleClickTrigger);
      triggerNode.addEventListener('keydown', (e) =>
        e.keyCode === KEY_ENTER ? this.handleClickTrigger(e) : null
      );
    });
  }

  userHasSelectedText() {
    const selection = document.getSelection();

    return (selection.type === 'Range')
  }

  handleClickTrigger = (event) => {
    const rowNode = event.currentTarget.parentNode;
    const open = !(rowNode.getAttribute('aria-expanded') === 'true');

    if (!this.userHasSelectedText()) {
      rowNode.setAttribute('aria-expanded', open ? 'true' : 'false');
    }
  };
}

export default ScoreTableBehaviour;
