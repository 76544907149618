import React from 'react';

function Icon({ id, ...restProps }) {
  return (
    <svg className={`icon icon--${id}`} {...restProps}>
      <use xlinkHref={`#icon-${id}`} />
    </svg>
  );
}

export default Icon;
