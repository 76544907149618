import React, { memo } from 'react';
import clsx from 'clsx';

import './styles.scss';

import {
  getOriginAngle,
  getScoreDotStartAngle,
  getScoreDotOriginRadius,
  getCoords,
} from 'components/WheelChart/helpers';

import { CHART_TYPE_ARC } from '../constants';

export function ScoreBar({
  angle,
  angleStart,
  className,
  status,
  countryCode,
  cx,
  cy,
  radius,
  score,
  type = CHART_TYPE_ARC,
  sections,
  dotRadii,
  showGuides = true,
  labelRadius,
}) {
  const originAngle = getOriginAngle(type, angleStart, angle);

  const { outerDotRad, innerDotRad, scoreDotRad, dotOverlapDiameter } = dotRadii;

  return (
    <>
      <g
        className={clsx(
          'wheel__score-bar',
          status && `wheel__score-bar--${status.toLowerCase()}`,
          className
        )}
        data-country={countryCode}
      >
        <g className="wheel__score-bar--background">
          {sections.map((angle, index) => {
            const originRadius = getScoreDotOriginRadius(type, radius, dotOverlapDiameter, index);
            const startAngle = getScoreDotStartAngle(type, angle, index, originAngle, originRadius);
            const { x, y } = getCoords(startAngle, originRadius, cx, cy);
            return (
              <circle
                key={index}
                cx={x}
                cy={y}
                r={outerDotRad}
                style={{ transformOrigin: `${x}px ${y}px`, transform: `rotate(${angle}deg)` }}
              />
            );
          })}
        </g>
        <g className="wheel__score-bar--foreground">
          {sections.map((angle, index) => {
            const originRadius = getScoreDotOriginRadius(type, radius, dotOverlapDiameter, index);
            const startAngle = getScoreDotStartAngle(type, angle, index, originAngle, originRadius);
            const { x, y } = getCoords(startAngle, originRadius, cx, cy);
            return (
              <circle
                key={index}
                cx={x}
                cy={y}
                r={innerDotRad}
                style={{ transformOrigin: `${x}px ${y}px` }}
              />
            );
          })}
        </g>
        <g className="wheel__score-bar--score">
          {sections.map((angle, index) => {
            if (index < score) {
              const originRadius = getScoreDotOriginRadius(type, radius, dotOverlapDiameter, index);
              const startAngle = getScoreDotStartAngle(
                type,
                angle,
                index,
                originAngle,
                originRadius
              );
              const { x, y } = getCoords(startAngle, originRadius, cx, cy);
              return (
                <g key={index}>
                  <circle
                    cx={x}
                    cy={y}
                    r={scoreDotRad}
                    style={{ transformOrigin: `${x}px ${y}px` }}
                  />
                  {status === 'provisional' && (
                    <circle
                      cx={x}
                      cy={y}
                      r={scoreDotRad * 0.55}
                      style={{ transformOrigin: `${x}px ${y}px` }}
                    />
                  )}
                </g>
              );
            }
            return null;
          })}
        </g>
        {showGuides && (
          <g>
            {sections.map((angle, index) => {
              const originRadius = getScoreDotOriginRadius(
                type,
                labelRadius,
                dotOverlapDiameter,
                index
              );
              const startAngle = getScoreDotStartAngle(
                type,
                angle,
                index,
                originAngle,
                originRadius
              );
              const { x, y } = getCoords(startAngle, originRadius, cx, cy);
              if (index === 0) {
                return (
                  <line
                    x1={cx}
                    y1={cy}
                    x2={x}
                    y2={y}
                    style={{ strokeWidth: 0.5, stroke: 'aqua', fill: 'none' }}
                  />
                );
              }
            })}
          </g>
        )}
      </g>
    </>
  );
}

const ScoreBars = (props) =>
  props.data.map((datum, index) => (
    <ScoreBar
      angleStart={props.angle * index}
      countryCode={datum.code}
      key={datum.code}
      score={datum.score}
      status={datum.status}
      {...props}
    />
  ));

export default memo(ScoreBars);
