import degToRad from 'utils/degreesToRadians';
import mapScales from 'utils/mapScales';

import {
  CHART_TYPE_ARC,
  CHART_TYPE_ROTATE,
  DOT_OVERLAP_REDUCTION,
  MAX_OUTER_DOT_RADIUS,
  MAX_INNER_DOT_RADIUS,
  MAX_SCORE_DOT_RADIUS,
  MIN_SCORE_DOT_RADIUS,
  DOT_OVERLAP_PERCENTAGE,
  TITLE_TEXT_MIN_WIDTH,
  TITLE_TEXT_PADDING_PC,
} from '../constants';

export const getSections = (type, maxScore, angleSegment) => {
  if (type === CHART_TYPE_ARC) {
    return Array(maxScore).fill(angleSegment / (maxScore + 1));
  }
  if (type === CHART_TYPE_ROTATE) {
    return Array(maxScore).fill(angleSegment / 2);
  }
  return null;
};

// export const getOriginAngle = (type, angleStart, angle) =>
//   type === CHART_TYPE_ROTATE ? angleStart - angle / 2 : angleStart;

export const getOriginAngle = (type, angleStart, angle) =>
  type === CHART_TYPE_ROTATE ? angleStart : angleStart;

export const getScoreDotStartAngle = (type, val, ind, originAngle, radius) => {
  const multiplier = type === CHART_TYPE_ARC ? ind : 0;
  if (type === CHART_TYPE_ARC && radius >= 306) {
    return originAngle + mapScales(radius, 306, 1500, 3, 0.5) * multiplier;
  }
  return originAngle + val * multiplier;
};

// getScoreDotStartAngle(type, angle, index, originAngle, originRadius);

export const getScoreDotOriginRadius = (type, baseRad, dotOverlapDiameter, i) =>
  type === CHART_TYPE_ROTATE ? baseRad + dotOverlapDiameter * i : baseRad;

export const getArcLength = (angle, radius) => 2 * Math.PI * radius * (angle / 360);

export const getAngleFromLength = (length, radius) => (length / (2 * Math.PI * radius)) * 360;

export const getFocusCountries = (indexAngle, data, angleSegment) => {
  const topItem = getItemByAngle(indexAngle, data, angleSegment);
  // const bottomItem = getItemByAngle(indexAngle + 180, data, angleSegment);
  return [topItem];
};

function wrapNumber(n, max) {
  return ((n % max) + max) % max;
}

function getItemByAngle(indexAngle, data, angleSegment) {
  let delta = Math.round(indexAngle / angleSegment) * -1;
  const index = wrapNumber(delta, data.length);
  return data[index];
}

export const findClosestSegment = (angle, angleSegment) => {
  return Math.round(angle / angleSegment) * angleSegment;
};

export const getCoords = (angleStart, radius, cx, cy) => {
  const angleStartRadians = degToRad(angleStart);
  const x = cx + radius * Math.cos(angleStartRadians);
  const y = cy + radius * Math.sin(angleStartRadians);
  return { x, y };
};

export const getPositionFromCenter = (e, node) => {
  const rect = node.getBoundingClientRect();
  const centerX = rect.left + rect.width / 2;
  const centerY = rect.top + rect.height / 2;

  return !e.touches
    ? {
        x: centerX - e.clientX,
        y: -(centerY - e.clientY),
      }
    : {
        x: centerX - e.touches[0].clientX,
        y: -(centerY - e.touches[0].clientY),
      };
};

export const getScoreSegment = (angleSegment, radius, maxLength) => {
  const arcLength = getArcLength(angleSegment, radius);
  return arcLength > maxLength ? getAngleFromLength(maxLength, radius) : angleSegment;
};

export const getScoreLength = (dotRadius) => 2 * dotRadius * DOT_OVERLAP_PERCENTAGE;

export const getDotRadii = (type, arcLength, maxScore, innerRadius, outerRadius) => {
  let outerDotRad;
  let innerDotRad;
  let scoreDotRad;

  if (type === CHART_TYPE_ARC) {
    outerDotRad = arcLength / maxScore - DOT_OVERLAP_REDUCTION;
    innerDotRad = outerDotRad;
    scoreDotRad = innerDotRad - 3;
  }

  if (type === CHART_TYPE_ROTATE) {
    outerDotRad = (outerRadius - innerRadius) / (maxScore + 1) / DOT_OVERLAP_PERCENTAGE / 2;
    innerDotRad = outerDotRad;
    scoreDotRad = outerDotRad - 3;
  }

  if (outerDotRad > MAX_OUTER_DOT_RADIUS) {
    outerDotRad = MAX_OUTER_DOT_RADIUS;
  }

  if (innerDotRad > MAX_INNER_DOT_RADIUS) {
    innerDotRad = MAX_INNER_DOT_RADIUS;
  }

  if (scoreDotRad > MAX_SCORE_DOT_RADIUS) {
    scoreDotRad = MAX_SCORE_DOT_RADIUS;
  }

  if (scoreDotRad < MIN_SCORE_DOT_RADIUS) {
    scoreDotRad = MIN_SCORE_DOT_RADIUS;
  }

  const dotOverlapDiameter = getScoreLength(outerDotRad, maxScore);

  return { outerDotRad, innerDotRad, scoreDotRad, dotOverlapDiameter };
};

export const getInnerRadius = (type, width, maxRad, maxScore) => {
  const overlayWidth = width * TITLE_TEXT_PADDING_PC;
  const titleTextWidth = Math.max(overlayWidth, TITLE_TEXT_MIN_WIDTH);
  const minInnerRadius = titleTextWidth / 2 + 4;
  const maxInnerRadius =
    type === CHART_TYPE_ROTATE
      ? maxRad - MAX_OUTER_DOT_RADIUS * 2 * DOT_OVERLAP_PERCENTAGE * maxScore
      : maxRad - MAX_OUTER_DOT_RADIUS * 2;

  return Math.max(minInnerRadius, maxInnerRadius);
};
